<template>
  <section class="antar-barang">
    <div class="container-scroller">
      <div class="page-header">
        <h3 class="page-title">
          &nbsp;
        </h3>
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
						<li class="breadcrumb-item">Operasional</li>          
						<li class="breadcrumb-item active" aria-current="page">Validasi Pembayaran</li>
					</ol>
				</nav>
      </div>          
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <h2 class="col-xl-10 col-lg-6 mx-auto text-center pb-5" style="color: #00b050;font-weight: 800;">Validasi Pembayaran</h2>            
            <div class="col-xl-10 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <form class="pt-3">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <b-row>
                              <b-col class="pt-0" lg="11">
                                <h6 class="mb-0 mt-1 mb-3">Pengirim</h6>
                                <template v-if="data.uuid_type_customers == 'mhZxPQQipb8SSeo'">
                                  <!-- Pribadi -->
                                  <div class="form-group">
                                    <b-row>
                                      <b-col lg="6"><h6>Nama Pengirim</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.sender_details.name_sender" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>Nomor Telepon</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.sender_details.phone" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>Alamat</h6></b-col>
                                    </b-row>
                                    <p>{{ data.sender_details.address }}</p>
                                    <!-- <textarea v-model="" class="form-control form-control-lg mb-3" disabled></textarea> -->
                                    
                                    <b-row>
                                      <b-col lg="6"><h6>Detail Alamat</h6></b-col>
                                    </b-row>
                                    <textarea v-model="data.sender_details.address_detail" class="form-control form-control-lg mb-3" disabled></textarea>

                                    <b-row>
                                      <b-col lg="6"><h6>Catatan</h6></b-col>
                                    </b-row>
                                    <textarea v-model="data.sender_details.note_sender" class="form-control form-control-lg mb-3" disabled></textarea>                                
                                  </div>
                                </template>                            
                                <template v-else-if="data.uuid_type_customers == '6rredhwyWgWdSpN'">
                                  <!-- Perusahaan -->
                                  <div class="form-group">
                                    <b-row>
                                      <b-col lg="6"><h6>Nama Perusahaan</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.sender_details.company_name_sender" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>PIC Perusahaan</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.sender_details.pic_sender" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>Nomor Telepon</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.sender_details.phone" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>Alamat</h6></b-col>
                                    </b-row>
                                    <p>{{ data.sender_details.address }}</p>
                                    <!-- <textarea v-model="" class="form-control form-control-lg mb-3" disabled></textarea> -->
                                    
                                    <b-row>
                                      <b-col lg="6"><h6>Detail Alamat</h6></b-col>
                                    </b-row>
                                    <textarea v-model="data.sender_details.address_detail" class="form-control form-control-lg mb-3" disabled></textarea>

                                    <b-row>
                                      <b-col lg="6"><h6>Catatan</h6></b-col>
                                    </b-row>
                                    <textarea v-model="data.sender_details.note_sender" class="form-control form-control-lg mb-3" disabled></textarea>                                
                                  </div>                              
                                </template>
                              </b-col>                          
                            </b-row>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <b-row>
                              <b-col class="pt-0" lg="11">
                                <h6 class="mb-0 mt-1 mb-3">Penerima</h6>
                                <template v-if="data.uuid_type_customers == 'mhZxPQQipb8SSeo'">
                                  <!-- Pribadi -->
                                  <div class="form-group">
                                    <b-row>
                                      <b-col lg="6"><h6>Nama Penerima</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.recipient_details.name_recipient" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>Nomor Telepon</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.recipient_details.phone" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>Alamat</h6></b-col>
                                    </b-row>
                                    <p>{{ data.recipient_details.address }}</p>
                                    <!-- <textarea v-model="data.recipient_details.address" class="form-control form-control-lg mb-3" disabled></textarea> -->
                                    
                                    <b-row>
                                      <b-col lg="6"><h6>Detail Alamat</h6></b-col>
                                    </b-row>
                                    <textarea v-model="data.recipient_details.address_detail" class="form-control form-control-lg mb-3" disabled></textarea>

                                    <b-row>
                                      <b-col lg="6"><h6>Catatan</h6></b-col>
                                    </b-row>
                                    <textarea v-model="data.recipient_details.note_recipient" class="form-control form-control-lg mb-3" disabled></textarea>                                
                                  </div>
                                </template>                            
                                <template v-else-if="data.uuid_type_customers == '6rredhwyWgWdSpN'">
                                  <!-- Perusahaan -->
                                  <div class="form-group">
                                    <b-row>
                                      <b-col lg="6"><h6>Nama Perusahaan</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.recipient_details.company_name_recipient" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>PIC Perusahaan</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.recipient_details.pic_recipient" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>Nomor Telepon</h6></b-col>
                                    </b-row>
                                    <input type="text" v-model="data.recipient_details.phone" class="form-control form-control-lg mb-3" disabled="disabled">

                                    <b-row>
                                      <b-col lg="6"><h6>Alamat</h6></b-col>
                                    </b-row>
                                    <p>{{ data.recipient_details.address }}</p>
                                    <!-- <textarea v-model="" class="form-control form-control-lg mb-3" disabled></textarea> -->
                                    
                                    <b-row>
                                      <b-col lg="6"><h6>Detail Alamat</h6></b-col>
                                    </b-row>
                                    <textarea v-model="data.recipient_details.address_detail" class="form-control form-control-lg mb-3" disabled></textarea>

                                    <b-row>
                                      <b-col lg="6"><h6>Catatan</h6></b-col>
                                    </b-row>
                                    <textarea v-model="data.recipient_details.note_recipient" class="form-control form-control-lg mb-3" disabled></textarea>                                
                                  </div>                              
                                </template>                            
                              </b-col>                          
                            </b-row>                        
                          </div>                          
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <b-row>
                              <b-col class="pt-0" lg="11">
                                <h6 class="mb-0 mt-1 mb-3">Barang</h6>
                                <div class="form-group">
                                  <b-row>
                                    <b-col lg="6"><h6>Nama Barang</h6></b-col>
                                  </b-row>
                                  <input type="text" v-model="data.desc_package" class="form-control form-control-lg mb-3" disabled="disabled">

                                  <b-row>
                                    <b-col lg="6"><h6>Ukuran</h6></b-col>
                                  </b-row>
                                  <input type="text" v-model="data.package_size_name" class="form-control form-control-lg mb-3" disabled="disabled">

                                  <b-row>
                                    <b-col lg="6"><h6>Berat (gr)</h6></b-col>
                                  </b-row>
                                  <input type="text" v-model="data.weight" class="form-control form-control-lg mb-3" disabled="disabled">
                                  
                                  <b-row>
                                    <b-col lg="6"><h6>Dimensi (m3)</h6></b-col>
                                  </b-row>
                                  <input type="text" v-model="data.dimension" class="form-control form-control-lg mb-3" disabled="disabled">
                                </div>                                
                              </b-col>                          
                            </b-row>                        
                          </div>                          
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <b-row>
                              <b-col class="pt-0" lg="11">
                                <h6 class="mb-0 mt-1 mb-3">Lainnya</h6>
                                <div class="form-group">
                                  <b-row>
                                    <b-col lg="6"><h6>Tipe Pengiriman</h6></b-col>
                                  </b-row>
                                  <input type="text" v-model="data.delivery_types_name" class="form-control form-control-lg mb-3" disabled="disabled">

                                  <b-row>
                                    <b-col lg="12"><h6>Metode Pengiriman</h6></b-col>
                                  </b-row>
                                  <input type="text" v-model="data.shipping_methods_name" class="form-control form-control-lg mb-3" disabled="disabled">

                                  <b-row>
                                    <b-col lg="12"><h6>Metode Pembayaran</h6></b-col>
                                  </b-row>
                                  <input type="text" v-model="data.payment_type_name" class="form-control form-control-lg mb-3" disabled="disabled">
                                  
                                  <b-row>
                                    <b-col lg="6"><h6>Total Bayar</h6></b-col>
                                  </b-row>
                                  <input type="text" v-model="data.package_total_price" class="form-control form-control-lg mb-3" disabled="disabled">
                                </div>                                
                              </b-col>                          
                            </b-row>                        
                          </div>                          
                        </div>
                        
                        <div class="col-lg-12">
                          <div class="form-group">
                            <b-row>
                              <b-col class="pt-0" lg="11">
                                <h6 class="mb-0 mt-1 mb-2">Bukti Pembayaran</h6>                                
                                <img class="pt-2" :src="data.image_evidence_of_transfer">                                
                              </b-col>                          
                            </b-row>                        
                          </div>                          
                        </div>                        
                      </div>                                                                  
                    </div>
                  </div> 

                  <div class="mt-3">
                    <div class="row">
                      <div class="col-lg-6">
                        <b-button ref="containerButton" class="w-60 btn btn-block btn-gradient-danger btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info">Tidak Sesuai</b-button>
                      </div>
                      <div class="col-lg-6">
                        <b-button ref="containerButton" class="w-60 btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onNext">Sesuai dan Terima Pembayaran</b-button>                        
                      </div>                      
                    </div>                                        
                    <!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
import axios from 'axios'
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'login',
  data(){
    return{
      passwordHidden: false,
      params:{
        uuid: this.$route.params.uuid
      },
      unitDimension : "cm",
      cubicM: "\u33A5",
      errorMassage:0,
      form:{
        route: '',
        route_title: 'pengiriman_paket',        
        uuid_payment_methods: '',
        created_by: localStorage.getItem("uuid"),
      },
      alert:{
        status: 0,
        message:'',
      },
      data: [],
      dataOption1: [],
      optSelect: '',
      optionsRadio: [],
      selected: 'first', 
    }
  },
  methods:{
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),        
    getPaymentMethod: function () {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/payment-method?search=-&page=1&limit=999`,packageGlobals.configAxios)      
			.then((response) => {
        // this.dataOptionServices = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption1.push(pushData)
        });
			})							
    },
    getData: function (uuid) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/operational/antar-barang/${uuid}`,packageGlobals.configAxios)
			.then((response) => {
        this.form.route = '/operasional/antar-barang/pengiriman-paket/'+this.params.uuid
        this.form.uuid_payment_methods = response.data.result.uuid_payment_methods                        
        this.data = response.data.result

        this.data.image_evidence_of_transfer = `${packageStore.mainApi}${this.data.image_evidence_of_transfer}`
			})      
    },    
    onDiscount()
    {
      this.$router.push(`/operasional/antar-barang/voucher/${this.params.uuid}`)
    },
    // onNext(evt) {
    onPending() {      
      this.form.uuid_payment_methods = this.optSelect['id']
      console.log(this.form);
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/${this.params.uuid}`,this.form)
      .then((response) => {
        this.data = response
        this.$router.push(`/operasional/antar-barang/pembayaran/${this.params.uuid}`)
      })
    },      
    onNext() {      
      this.form.uuid_payment_methods = this.optSelect['id']
      console.log(this.form);
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/accept-payment/${this.params.uuid}`,this.form)
      .then((response) => {
        this.responseAxios(response)        
        this.$router.push(`/operasional/validasi-pembayaran`)
      })
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				// this.getAll('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },    
  },
  mounted() {
		this.getPaymentMethod()
    this.getData(this.params.uuid)        
    // this.getShippingMethod('-',1,999)    
  }  
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}

.next-breadcrumb a
{
	padding-top: 0px;
}

.custom-control-label{
  padding-top: 4px;
  font-size: 16px!important;
}

.input-group-text{
  font-weight: bold;
}
</style>